import React, { useState } from 'react';

const Filter = ({ onFilterChange }) => {
  const [performance, setPerformance] = useState('');

  const handleChange = (event) => {
    setPerformance(event.target.value);
    onFilterChange(event.target.value);
  };

  return (
    <div className="p-4">
      <label htmlFor="performance-filter" className="block text-sm font-medium text-gray-700">Filter by Performance</label>
      <select
        id="performance-filter"
        value={performance}
        onChange={handleChange}
        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option value="All">All</option>
        <option value="High">High</option>
        <option value="Medium-High">Medium-High</option>
        <option value="Medium">Medium</option>
        <option value="Low">Low</option>
      </select>
    </div>
  );
};

export default Filter;