import React from 'react';
import OrgChart from 'react-orgchart';
import 'react-orgchart/index.css';
import './OrgChart.css';

const NodeComponent = ({ node }) => {
  console.log('Rendering node:', node);

  const name = node.name || 'No Name';
  const role = node.title || 'No Role';

  return (
    <div className="node">
      <strong>{name}</strong><br />
      {role}
    </div>
  );
};

const OrgChartComponent = ({ data }) => {
  const transformDataToHierarchy = (employees) => {
    const map = {};
    const roots = [];

    console.log('Transforming data to hierarchy...');
    console.log('Raw employee data:', JSON.stringify(employees, null, 2));

    // Create a map with employee name as key and employee object as value
    employees.forEach(employee => {
      if (!employee['Name'] || !employee['Role']) {
        console.warn('Skipping invalid employee data:', JSON.stringify(employee, null, 2));
        return;
      }
      map[employee['Name']] = { 
        name: employee['Name'], 
        title: employee['Role'], 
        children: [] 
      };
    });

    console.log('Employee map:', JSON.stringify(map, null, 2));

    // Build hierarchy by linking children to their primary manager
    employees.forEach(employee => {
      const currentEmployee = map[employee['Name']];
      if (!currentEmployee) {
        console.warn('Employee not found in map:', JSON.stringify(employee, null, 2));
        return;
      }

      console.log('Processing employee:', JSON.stringify(employee, null, 2));

      let manager = employee['Manager'] ? employee['Manager'].trim() : null;

      if (manager) {
        console.log(`Looking for manager: ${manager}`);
        if (map[manager]) {
          map[manager].children.push(currentEmployee);
          console.log(`Added ${employee['Name']} under manager ${manager}`);
        } else {
          console.warn(`Manager not found: ${manager}`);
        }
      } else {
        roots.push(currentEmployee);
        console.log(`Added ${employee['Name']} as a root employee`);
      }
    });

    console.log('Hierarchical data:', JSON.stringify(roots, null, 2));

    return roots.length > 0 ? { name: 'Company Structure', title: 'Top Level', children: roots } : null;
  };

  console.log('Data received by OrgChartComponent:', JSON.stringify(data, null, 2));
  const orgData = transformDataToHierarchy(data);
  console.log('OrgData structure:', JSON.stringify(orgData, null, 2));

  return (
    <div className="org-chart-container">
      {orgData ? (
        <OrgChart 
          tree={orgData} 
          NodeComponent={NodeComponent} 
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default OrgChartComponent;