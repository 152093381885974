import React, { useState, useCallback } from 'react';
import './styles/App.css';
import Sidebar from './components/Sidebar';
import DataSection from './components/DataSection';
import EmployeeProfile from './components/EmployeeProfile';
import Dashboard from './components/Dashboard';
import OrgChartComponent from './components/OrgChart'; // Ensure this matches the file name
import { overallDashboardConfig, performanceDashboardConfig, skillsDashboardConfig, customDashboardConfig, compensationDashboardConfig } from './chartConfig';
import { DASHBOARD_TYPES } from './constants';

const App = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [currentDashboard, setCurrentDashboard] = useState('DATA');

  const handleFilterChange = (filter) => {
    if (filter === '') {
      setFilteredData(data);
    } else {
      const filtered = data.filter(emp => 
        emp['Performance Metrics'] && emp['Performance Metrics'].toLowerCase() === filter.toLowerCase()
      );
      setFilteredData(filtered);
    }
  };

  const handleDataLoad = useCallback((parsedData) => {
    setData(parsedData);
    setFilteredData(parsedData);
  }, []);

  const handleDashboardChange = (dashboard) => {
    setCurrentDashboard(dashboard);
  };

  const renderDashboard = () => {
    switch (currentDashboard) {
      case 'DATA':
        return <DataSection onFilterChange={handleFilterChange} onUploadComplete={handleDataLoad} data={filteredData} />;
      case 'EMPLOYEE_PROFILE':
        return <EmployeeProfile data={filteredData} />;
      case 'COMPENSATION':
        return <Dashboard config={compensationDashboardConfig} data={filteredData} />;
      case DASHBOARD_TYPES.SKILLS:
        return <Dashboard config={skillsDashboardConfig} data={filteredData} />;
      case DASHBOARD_TYPES.CUSTOM:
        return <Dashboard config={customDashboardConfig} data={filteredData} />;
      case DASHBOARD_TYPES.OVERALL:
        return <Dashboard config={overallDashboardConfig} data={filteredData} />;
      case DASHBOARD_TYPES.PERFORMANCE:
        return <Dashboard config={performanceDashboardConfig} data={filteredData} />;
      case 'ORG_CHART':
        return <OrgChartComponent data={filteredData} />;
      default:
        return <DataSection onFilterChange={handleFilterChange} onUploadComplete={handleDataLoad} data={filteredData} />;
    }
  };

  return (
    <div className="flex">
      <Sidebar onDashboardChange={handleDashboardChange} />
      <div className="flex-1 ml-64 p-6">
        {renderDashboard()}
      </div>
    </div>
  );
};

export default App;