// src/constants.js
export const DASHBOARD_TYPES = {
  DATA: 'DATA',
  EMPLOYEE_PROFILE: 'EMPLOYEE_PROFILE',
  COMPENSATION: 'COMPENSATION',
  SKILLS: 'SKILLS',
  CUSTOM: 'CUSTOM',
  OVERALL: 'OVERALL',
  PERFORMANCE: 'PERFORMANCE',
  ORG_CHART: 'ORG_CHART',
  MAP_VIEW: 'MAP_VIEW',
  LOW_COST_LOCATIONS: 'LOW_COST_LOCATIONS',
  COMPANY_CALENDAR: 'COMPANY_CALENDAR',
  PRODUCTIVITY_METRICS: 'PRODUCTIVITY_METRICS',
  INDUSTRY_BENCHMARKS: 'INDUSTRY_BENCHMARKS',
  ORG_STRUCTURE_ANALYSIS: 'ORG_STRUCTURE_ANALYSIS',
};