// src/chartConfig.js

export const performanceDashboardConfig = {
  title: 'Performance Dashboard',
  charts: [
    {
      type: 'scatter',
      xField: 'Name',
      yField: 'Performance Metrics',
      data: {
        datasets: [
          {
            label: 'Employee Performance',
            data: [],
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: 'category',
            title: {
              display: true,
              text: 'Employee Name',
            },
          },
          y: {
            type: 'category',
            labels: ['Low', 'Medium', 'Medium-High', 'High'],
            title: {
              display: true,
              text: 'Performance Metrics',
            },
            reverse: true, // Flip the y-axis
          },
        },
      },
    },
  ],
  tables: [],
};

export const compensationDashboardConfig = {
  title: 'Compensation Dashboard',
  charts: [
    {
      type: 'scatter',
      xField: 'Name',
      yField: 'Compensation',
      data: {
        datasets: [
          {
            label: 'Employee Compensation',
            data: [],
            backgroundColor: 'rgba(153, 102, 255, 0.6)',
            borderColor: 'rgba(153, 102, 255, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: 'category',
            title: {
              display: true,
              text: 'Employee Name',
            },
          },
          y: {
            type: 'linear',
            title: {
              display: true,
              text: 'Compensation',
            },
          },
        },
      },
    },
  ],
  tables: [],
};

export const orgChartConfig = {
  title: 'Org Chart',
  data: {
    name: 'CEO',
    title: 'John Doe',
    children: [
      {
        name: 'Manager',
        title: 'Jane Smith',
        children: [
          {
            name: 'Employee',
            title: 'Joe Brown'
          }
        ]
      }
    ]
  },
};

export const overallDashboardConfig = {
  title: 'Overall Dashboard',
  charts: [
    {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Overall Metrics',
            data: [],
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Metrics',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Values',
            },
          },
        },
      },
    },
  ],
  tables: [],
};

export const skillsDashboardConfig = {
  title: 'Skills Dashboard',
  charts: [
    {
      type: 'bar',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Skills Metrics',
            data: [],
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Skills',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Values',
            },
          },
        },
      },
    },
  ],
  tables: [],
};

export const customDashboardConfig = {
  title: 'Custom Dashboard',
  charts: [
    {
      type: 'line',
      data: {
        labels: [],
        datasets: [
          {
            label: 'Custom Metrics',
            data: [],
            backgroundColor: 'rgba(75, 192, 192, 0.6)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Metrics',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Values',
            },
          },
        },
      },
    },
  ],
  tables: [],
};