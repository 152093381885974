import React, { useEffect, useRef } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ScatterController } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, ScatterController);

const Chart = ({ config, data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    let chartInstance;
    if (config.type === 'bar') {
      chartInstance = new ChartJS(ctx, {
        type: 'bar',
        data: {
          labels: data.map(item => item[config.xField]),
          datasets: [{
            label: config.yField,
            data: data.map(item => parseFloat(item[config.yField].replace(/[^0-9.-]+/g, ""))), // Ensure numeric conversion
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          }],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: config.title,
            },
          },
        },
      });
    } else if (config.type === 'scatter') {
      console.log('Creating scatter chart with data:', data); // Debug statement
      chartInstance = new ChartJS(ctx, {
        type: 'scatter',
        data: {
          datasets: [{
            label: config.title,
            data: data.map(item => ({
              x: item[config.xField],
              y: config.yField === 'Performance Metrics' ? item[config.yField] : parseFloat(item[config.yField].replace(/[^0-9.-]+/g, "")), // Ensure numeric conversion for compensation
            })),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          }],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: config.title,
            },
          },
          scales: {
            x: {
              type: 'category',
              title: {
                display: true,
                text: config.xField,
              },
            },
            y: {
              type: config.yField === 'Performance Metrics' ? 'category' : 'linear',
              title: {
                display: true,
                text: config.yField,
              },
              ...(config.yField === 'Performance Metrics' ? { labels: ['Low', 'Medium', 'Medium-High', 'High'], reverse: true } : {}),
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [config, data]);

  return <canvas ref={chartRef}></canvas>;
};

export default Chart;