// src/components/Dashboard.js
import React from 'react';
import Chart from './Chart';
import Table from './Table';
import OrgChart from './OrgChart'; // Ensure this is imported

const Dashboard = ({ config, data }) => {
  return (
    <div>
      <h2 className="text-xl font-bold mb-4">{config.title}</h2>
      {config.charts.map((chartConfig, index) => (
        <div key={index} className="mb-4">
          <Chart config={chartConfig} data={data} />
        </div>
      ))}
      {config.tables.map((tableConfig, index) => (
        <div key={index} className="mb-4">
          <Table config={tableConfig} data={data} />
        </div>
      ))}
      {config.title === 'Organizational Structure Analysis' && <OrgChart data={data} />}
    </div>
  );
};

export default Dashboard;