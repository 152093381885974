// src/components/Sidebar.js
import React from 'react';
import './Sidebar.css';

const Sidebar = ({ onDashboardChange }) => {
  return (
    <div className="sidebar fixed inset-y-0 left-0 bg-gray-800 text-white w-64 flex flex-col overflow-y-auto">
      <div className="sidebar-header">Organizational Optimizer</div>
      <nav className="flex-1 px-2 py-4 space-y-1">
        <button
          onClick={() => onDashboardChange('DATA')}
          className="w-full text-left px-4 py-2 rounded hover:bg-gray-700"
        >
          Data
        </button>
        <div className="mt-4">
          <div className="font-semibold">Dashboards</div>
          <button
            onClick={() => onDashboardChange('OVERALL')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Overall Dashboard
          </button>
          <button
            onClick={() => onDashboardChange('ORG_CHART')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Org Chart View
          </button>
          <button
            onClick={() => onDashboardChange('EMPLOYEE_PROFILE')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Employee Profile
          </button>
          <button
            onClick={() => onDashboardChange('COMPENSATION')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Compensation Dashboard
          </button>
          <button
            onClick={() => onDashboardChange('SKILLS')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Skills Dashboard
          </button>
          <button
            onClick={() => onDashboardChange('PERFORMANCE')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Performance Dashboard
          </button>
          <button
            onClick={() => onDashboardChange('MAP_VIEW')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Map View
          </button>
          <button
            onClick={() => onDashboardChange('LOW_COST_LOCATIONS')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Low Cost Locations
          </button>
          <button
            onClick={() => onDashboardChange('COMPANY_CALENDAR')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Company Calendar
          </button>
          <button
            onClick={() => onDashboardChange('PRODUCTIVITY_METRICS')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Productivity Metrics
          </button>
          <button
            onClick={() => onDashboardChange('INDUSTRY_BENCHMARKS')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Industry Benchmarks
          </button>
          <button
            onClick={() => onDashboardChange('CUSTOM')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Custom Dashboard
          </button>
        </div>
        <div className="mt-4">
          <div className="font-semibold">Analysis</div>
          <button
            onClick={() => onDashboardChange('ORG_STRUCTURE_ANALYSIS')}
            className="w-full text-left px-4 py-2 rounded hover:bg-gray-700 ml-4"
          >
            Organizational Structure Analysis
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;