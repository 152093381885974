// src/components/EmployeeProfile.js
import React from 'react';

const EmployeeProfile = ({ data }) => {
  return (
    <div className="employee-profile p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Employee Profile</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Role</th>
            <th className="py-2 px-4 border-b">Department</th>
            <th className="py-2 px-4 border-b">Performance Metrics</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td className="py-2 px-4 border-b">{row['Name']}</td>
              <td className="py-2 px-4 border-b">{row['Role']}</td>
              <td className="py-2 px-4 border-b">{row['Department']}</td>
              <td className="py-2 px-4 border-b">{row['Performance Metrics']}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeProfile;