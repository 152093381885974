import React from 'react';

const UploadCSV = ({ onUploadComplete }) => {
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target.result;
      if (onUploadComplete) {
        onUploadComplete(csvData);
      }
    };
    reader.readAsText(file);
  };

  return (
    <div>
      <input type="file" accept=".csv" onChange={handleUpload} />
      <p>Upload a CSV file to see the data</p>
    </div>
  );
};

export default UploadCSV;