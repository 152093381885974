import React, { useState, useCallback } from 'react';
import UploadCSV from './UploadCSV';
import Filter from './Filter';
import Table from './Table';
import Papa from 'papaparse';
import './DataSection.css';

const DataSection = ({ onFilterChange, onUploadComplete, data }) => {
  const [filteredData, setFilteredData] = useState(data);

  const handleDataLoad = useCallback((csvText) => {
    Papa.parse(csvText, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const parsedData = result.data;
        setFilteredData(parsedData);
        onUploadComplete(parsedData);
      },
      error: (error) => {
        console.error("Error parsing CSV data:", error);
      }
    });
  }, [onUploadComplete]);

  const handleFilterChange = (filterValue) => {
    if (filterValue === 'All' || filterValue === '') {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) =>
        item['Performance Metrics'] && item['Performance Metrics'].toLowerCase() === filterValue.toLowerCase()
      );
      setFilteredData(filtered);
    }
  };

  return (
    <div className="data-section">
      <button className="import-button">Import/Integrations</button>
      <div className="upload-info">
        <UploadCSV onUploadComplete={handleDataLoad} />
      </div>
      {filteredData.length === 0 && <div className="no-data">No data available</div>}
      <div className="filter-section mt-4">
        <Filter onFilterChange={handleFilterChange} />
      </div>
      {filteredData.length > 0 && (
        <Table data={filteredData} />
      )}
    </div>
  );
};

export default DataSection;